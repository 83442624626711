import React, { Fragment } from 'react';
import { openModal, api, submitForm, host } from '../Module';
import QRCode from 'qrcode.react';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Profile: {},
            StringToken: ""
        };
    }

    componentDidMount() {
        document.getElementById('loadingSpiner').style.display = "none";
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("controler/mastermurid_api", { act: "detail guru sendiri" }, true);
        if (sql.status == "sukses") this.setState({ Profile: sql.data });
    }

    async handleDetail() {
        let sql = await api("controler/mastermurid_api", { act: "detail guru sendiri" });
        if (sql.status == "sukses") {
            this.setState({ Profile: sql.data });
            openModal("modalEditProfile");
        }
    }

    handleLogout() {
        localStorage.removeItem("TokenApp");
        localStorage.removeItem("UserType");
        window.location.href = "./";
    }

    async handleQrCode() {
        let sql = await api("scan", { act: "buat token", TokenType: "data guru" });
        if (sql.status == "sukses") {
            this.setState({ StringToken: sql.token });
            setTimeout(() => {
                openModal("modalQR");
            }, 500);
        }
    }

    handleTutupModal() {
        document.getElementById('btnTutupPassword').click();
    }

    render() {
        return (
            <Fragment>
                <div className="container" style={{ textAlign: "center" }}>
                    <img src={this.state.Profile.Foto} style={{ width: "150px", height: "150px", borderRadius: "50%" }} />
                </div>
                <div className="list-group p-3">
                    <a href="#" className="list-group-item list-group-item-action" onClick={() => this.handleDetail()} aria-current="true">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Edit Profile</h5>
                        </div>
                        <p className="mb-1">Menu untuk edit profile</p>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action" data-bs-toggle="modal" data-bs-target="#modalPassword">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Ganti Password</h5>
                        </div>
                        <p className="mb-1">Menu untuk ganti kata sandi</p>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action" onClick={() => this.handleQrCode()}>
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Tampilkan QR-Code</h5>
                        </div>
                        <p className="mb-1">Menampilkan qrcode anda untuk absen atau mengikiti kelas</p>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action" onClick={(e) => this.handleLogout()}>
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Keluar</h5>
                        </div>
                        <p className="mb-1">Keluar aplikasi</p>
                    </a>
                </div>

                <div className="modal fade" id="modalQR" tabIndex="-1" aria-labelledby="modalAbsen" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">QR-Code</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <QRCode value={this.state.StringToken} style={{ width: "100%", height: "300px" }} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalPassword" tabIndex="-1" aria-labelledby="modalAbsen" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: "model/mastermurid_crud", debug: true, fn: () => this.handleTutupModal() })} noValidate>
                                <input type='hidden' name='act' value="ganti password guru" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Ganti Password</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Password Lama</label>
                                        <input type="password" className="form-control" name='PasswordLama' id="edtPwdLama" required />
                                        <div className="invalid-feedback">Silahkan masukan password lama</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Password Baru</label>
                                        <input type="password" className="form-control" name='PasswordBaru' id="edtPwdBaru" required />
                                        <div className="invalid-feedback">Silahkan masukan password baru</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Ulangi Password Baru</label>
                                        <input type="password" className="form-control" name='PasswordBaru2' id="edtPwdBaru2" required />
                                        <div className="invalid-feedback">Silahkan ulangi password baru</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupPassword'>Batal</button>
                                    <button type="submit" className="btn btn-danger"> <i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalEditProfile" tabIndex="-1" aria-labelledby="modalAbsen" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: "model/guru_crud", debug: true, fn: () => this.handleTutupModal() })} noValidate>
                                <input type='hidden' name='act' value="edit profile" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Data Diri</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="Email" className="form-control" name='Email' id="edtEmail" value={this.state.Profile.Email} onChange={(e) => {
                                            let data = this.state.Profile;
                                            data.Email = e.target.value;
                                            this.setState({ Profile: data });
                                        }} required />
                                        <div className="invalid-feedback">Silahkan masukan email</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Telp</label>
                                        <input type="number" className="form-control" name='Telp' id="Telp" value={this.state.Profile.Telp} onChange={(e) => {
                                            let data = this.state.Profile;
                                            data.Telp = e.target.value;
                                            this.setState({ Profile: data });
                                        }} required />
                                        <div className="invalid-feedback">Silahkan masukan no Telp</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Alamat</label>
                                        <input type="number" className="form-control" name='Alamat' id="Alamat" value={this.state.Profile.Alamat} onChange={(e) => {
                                            let data = this.state.Profile;
                                            data.Alamat = e.target.value;
                                            this.setState({ Profile: data });
                                        }} required />
                                        <div className="invalid-feedback">Silahkan masukan no alamat</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupEdit Profile'>Batal</button>
                                    <button type="submit" className="btn btn-default"> <i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Home;