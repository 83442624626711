import React, { Fragment, Component } from "react";
import { api, tanggalIndo, numberFormat } from "../Module";
import { withRouter } from "../withRouter";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Detail: {},
        };
    }

    async componentDidMount() {
        document.getElementById('loadingSpiner').style.display = "none";
    }

    handleNavigate = (path) => {
        this.props.navigate(path);
    };

    render() {
        return (
            <Fragment>
                <div className="container">
                    <h5 style={{ textAlign: "center" }}>Academy</h5>
                    <p></p>
                    <div className="row justify-content-center gy-3">
                        <div className="col-6 col-md-4">
                            <div className="card text-center shadow" onClick={() => this.handleNavigate("/perpus")}>
                                <div className="card-body">
                                    <div className="mb-2">
                                        <i
                                            className="fas fa-book-reader"
                                            style={{ fontSize: "80px" }}
                                        ></i>
                                    </div>
                                    <p className="card-text">Perpus</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div
                                className="card text-center shadow"
                                onClick={() => this.handleNavigate("/sarana")}
                            >
                                <div className="card-body">
                                    <div className="mb-2">
                                        <i
                                            className="fas fa-laptop"
                                            style={{ fontSize: "80px" }}
                                        ></i>
                                    </div>
                                    <p className="card-text">Sarana</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div
                                className="card text-center shadow"
                                onClick={() => this.handleNavigate("/pembayaran")}
                            >
                                <div className="card-body">
                                    <div className="mb-2">
                                        <i
                                            className="fas fa-money-bill-wave"
                                            style={{ fontSize: "80px" }}
                                        ></i>
                                    </div>
                                    <p className="card-text">Pembayaran</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div
                                className="card text-center shadow"
                                onClick={() => this.handleNavigate("/jadwalujian")}
                            >
                                <div className="card-body">
                                    <div className="mb-2">
                                        <i
                                            className="fas fa-user-graduate"
                                            style={{ fontSize: "80px" }}
                                        ></i>
                                    </div>
                                    <p className="card-text">Jadwal Ujian</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Home);