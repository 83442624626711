import React, { Fragment } from "react";
import { host, pesan, api, isJson } from './Module';
import "./App.css";

// MAIN
import MainGuru from './page/MainGuru';
import MainWali from './page/MainWali';
import MainMurid from './page/MainMurid';
// MAIN

function App() {
  let Token = localStorage.getItem("TokenApp");
  let UserType = localStorage.getItem("UserType");

  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        UserName: "",
        Password: "",
        UserType: "Siswa",
        Profile: {}
      };
      this.handleLogin = this.handleLogin.bind(this);
    }

    async componentDidMount() {
      let sql = await api("profile", { Domain: window.location.hostname });
      if (sql.status === "sukses") this.setState({ Profile: sql.data });
    }

    async handleLogin(e) {
      e.preventDefault();
      e.stopPropagation();
      let Form = e.target;
      let btn = Form.querySelector('button[type="submit"]');
      if (e.target.checkValidity()) {
        btn.disabled = true;
        let data = new FormData(Form);
        data.append("Domain", window.location.hostname);
        fetch(host + "login", {
          method: 'POST',
          body: data,
        }).then(response => response.text()).then(res => {
          if (isJson(res)) {
            let hasil = JSON.parse(res);
            if (hasil.status === "sukses") {
              localStorage.setItem("TokenApp", hasil.token);
              localStorage.setItem("UserType", this.state.UserType);
              window.location.reload();
            } else {
              btn.disabled = false;
              pesan(hasil.pesan);
            }
          } else {
            btn.disabled = false;
            console.log(res);
            pesan("Terjadi kesalahan");
          }
        }).catch((error) => {
          btn.disabled = false;
          console.log("Error: " + error);
        });
      } else {
        Form.classList.add('was-validated');
        btn.disabled = false;
      }
    }

    render() {
      return (
        <Fragment>
          <div className="container d-flex align-items-center justify-content-center">
            <div className="card" style={{ width: "400px" }}>
              <div className="card-header pt-4 d-flex justify-content-between align-items-center">
                <img src={require('./logo.png')} alt="Logo" style={{ width: '75px', height: '75px', borderRadius: "50%" }} />
                <h4>{this.state.Profile.Nama}</h4>
              </div>
              <div className="card-body">
                <form className="needs-validation" onSubmit={this.handleLogin} noValidate>
                  <div className="mb-6">
                    <label htmlFor="Email" className="form-label">Email</label>
                    <input type="text" className="form-control" name="UserName" value={this.state.UserName} onChange={(e) => this.setState({ UserName: e.target.value })} placeholder="Masukan Nomor Induk / Email" required />
                    <div className="invalid-feedback">Silahkan isi Email</div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Password" className="form-label">Password</label>
                    <input type="password" className="form-control" name="Password" value={this.state.Password} onChange={(e) => this.setState({ Password: e.target.value })} placeholder="Masukan Kata Sandi" required />
                    <div className="invalid-feedback">Silahkan isi Password</div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Password" className="form-label">Login Sebagai</label>
                    <select className="form-select" name="UserType" value={this.state.UserType} onChange={(e) => this.setState({ UserType: e.target.value })} required >
                      <option value="Siswa">Siswa</option>
                      <option value="Guru">Guru</option>
                      <option value="Wali">Wali Murid</option>
                      <option value="Admin">Admin / Staff</option>
                    </select>
                    <div className="invalid-feedback">Silahkan pilih</div>
                  </div>
                  <button type="submit" style={{ backgroundColor: "#32642d", color: "#fbbc05" }} className="btn btn-primary w-100">Masuk</button>
                </form>
              </div>
            </div>
          </div>
          <div id="toast"></div>
        </Fragment>
      )
    }
  }

  if (Token) {
    if (UserType == "Guru") {
      return <MainGuru />;
    } else if (UserType == "Siswa") {
      return <MainMurid />;
    } else if (UserType == "Wali") {
      return <MainWali />;
    }
  } else {
    return <Login />;
  }
}

export default App;
