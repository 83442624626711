import React, { Fragment, createRef } from 'react';
import { api, exportData, importData, saiki, submitForm, openModal, checkDateFormat } from '../Module';
import Hammer from 'hammerjs';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ModeView: "Jadwal Mengajar",
            DataPelajaran: [],
            DetailMapel: {},
            DataMurid: [],
            DetailMurid: {},
            DataWali: [],
            DataKelas: [],
            DetailKelas: {},
            DataJurusan: [],
            DataGuru: [],
            DetailPegawai: {},
            ImportField: [],
            ImportData: [],
            q: "",
            ID: "",
            D1: saiki(1),
            D2: saiki()
        };
        this.tabContentRef = createRef();
    }

    async componentDidMount() {
        document.getElementById('loadingSpiner').style.display = "block";
        this.handleMain();
        this.hammer = new Hammer(this.tabContentRef.current);
        this.hammer.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL });
        this.hammer.on('swipeleft panleft', () => this.activateTab('next'));
        this.hammer.on('swiperight panright', () => this.activateTab('prev'));
    }

    componentWillUnmount() {
        if (this.hammer) {
            this.hammer.destroy();
        }
    }

    activateTab = (direction) => {
        const tabs = Array.from(document.querySelectorAll('.nav-link')); // Ambil semua tab
        const activeTab = document.querySelector('.nav-link.active'); // Tab aktif
        const activeIndex = tabs.indexOf(activeTab); // Index tab aktif

        let newIndex = activeIndex; // Index baru
        if (direction === 'next' && activeIndex < tabs.length - 1) {
            newIndex = activeIndex + 1; // Geser ke kanan
        } else if (direction === 'prev' && activeIndex > 0) {
            newIndex = activeIndex - 1; // Geser ke kiri
        }

        if (newIndex !== activeIndex) {
            tabs[newIndex].click(); // Pindah tab menggunakan Bootstrap
        }
    };

    async handleMain() {
        if (this.state.ModeView == "Jadwal Mengajar") {
            let sql = await api("controler/guru_api", { act: "data pelajaran" }, true);
            if (sql.status == "sukses") this.setState({ DataPelajaran: sql.data, ID: "", DetailPelajaran: {} });
        } else if (this.state.ModeView == "Jurnal Kelas") {
            let sql = await api("controler/guru_api", { act: "data jurnal kelas", D1: this.state.D1, D2: this.state.D2 }, true);
            if (sql.status == "sukses") this.setState({ DataMurid: sql.data, ID: "", DetailMurid: {} });
        }
        document.getElementById('loadingSpiner').style.display = "none";
    }

    handleChangeMode(Mode) {
        document.getElementById('loadingSpiner').style.display = "block";
        this.setState({ ModeView: Mode });
        setTimeout(() => {
            this.handleMain();
        }, 500);
    }

    async handleLoadExcel(e) {
        let Data = [];
        let Jenis = this.state.ModeView;
        if (Jenis == "Mapel") {
            Data = await importData(e.target.files[0]);
        } else if (Jenis == "Siswa") {
            Data = await importData(e.target.files[0]);
        }
        this.setState({ ImportData: Data });
    }

    render() {
        return (
            <Fragment>
                <div className="container">
                    <h5 style={{ textAlign: "center" }}>Academy</h5>
                    <ul className="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={this.state.ModeView == "Jadwal Mengajar" ? "nav-link active" : "nav-link"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => this.handleChangeMode("Mapel")}>Jadwal Mengajar</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={this.state.ModeView == "Jurnal Kelas" ? "nav-link active" : "nav-link"} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => this.handleChangeMode("Siswa")}>Jurnal Kelas</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent" ref={this.tabContentRef}>
                        <div className={this.state.ModeView == "Jadwal Mengajar" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0"  style={{ height: "90vh", minHeight: "90vh" }}>
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>Hari</th>
                                            <th>Jam</th>
                                            <th>Mapel</th>
                                            <th>Kelas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.DataPelajaran.length > 0
                                                ?
                                                this.state.DataPelajaran.map((tr, i) => {
                                                    return (<tr key={i} onClick={() => this.handleBtnAdd(tr.ID)}>
                                                        <td>{tr.Hari}</td>
                                                        <td>{tr.Jam}</td>
                                                        <td>{tr.Pelajaran}</td>
                                                        <td>{tr.Kelas}</td>
                                                    </tr>)
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>Tidak ada data</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={this.state.ModeView == "Jurnal Kelas" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0"  style={{ height: "90vh", minHeight: "90vh" }}>
                            <p></p>
                            <div className='d-flex'>
                                <input type='date' className='form-control' value={this.state.D1} onChange={(e) => this.setState({ D1: e.target.value })} />
                                <input type='date' className='form-control' value={this.state.D2} onChange={(e) => this.setState({ D2: e.target.value })} />
                                <button className='btn btn-default' onClick={() => this.handleMain()}>
                                    <i className='fas fa-search'></i>
                                </button>
                            </div>
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>Tanggal</th>
                                            <th>Jam</th>
                                            <th>Kelas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.DataMurid.length > 0
                                                ?
                                                this.state.DataMurid.map((tr, i) => {
                                                    return (<tr key={i} onClick={() => this.handleBtnAdd(tr.ID)}>
                                                        <td>{tr.NIK}</td>
                                                        <td>{tr.NAMA}</td>
                                                        <td>{tr.Kelas}</td>
                                                    </tr>)
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>Tidak ada data</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalFormKelas" tabIndex="-1" aria-labelledby="modalKelas" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'dataguru', fn: () => this.handleMain(), debug: true })} noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Kelas" : "Edit Kelas"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type='hidden' name="act" value={this.state.ID == "" ? "tambah kelas" : "edit kelas"} />
                                    <input type='hidden' name="ID" value={this.state.ID} />
                                    <div className='form-group'>
                                        <label>Kode</label>
                                        <input type="text" className='form-control' name="Kode" id="edtKode" value={this.state.DetailKelas.Kode} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Kode = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan kode</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Nama</label>
                                        <input type="text" className='form-control' name="Nama" id="edtNama" value={this.state.DetailKelas.Nama} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Nama = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan nama</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Level</label>
                                        <input type="number" className='form-control' name="Level" id="edtLevel" value={this.state.DetailKelas.Level} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Level = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan level</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>GuruID</label>
                                        <select className='form-select' name="GuruID" id="edtGuruID" value={this.state.DetailKelas.GuruID} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.GuruID = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required >
                                            {
                                                this.state.DataGuru.map((opt, i) => {
                                                    return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                })
                                            }
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan Guru ID</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' name="Keterangan" id="edtKeterangan" value={this.state.DetailKelas.Keterangan} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Keterangan = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan keterangan</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Jurusan</label>
                                        <select className='form-select' name="Jurusan" id="edtJurusan" value={this.state.DetailKelas.Jurusan} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Jurusan = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} required>
                                            {
                                                this.state.DataJurusan.map((opt, i) => {
                                                    return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                })
                                            }
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan jurusan</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select className='form-select' name="Status" value={this.state.DetailKelas.Status} onChange={(e) => {
                                            let data = this.state.DetailKelas;
                                            data.Status = e.target.value;
                                            this.setState({ DetailKelas: data });
                                        }} >
                                            <option value="1">Aktif</option>
                                            <option value="0">Tidak Aktif</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan status</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                    <button type="submit" className="btn btn-default"><i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Home;