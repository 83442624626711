import React, { Fragment, createRef } from 'react';
import { api, tanggalIndo, numberFormat, saiki } from '../Module';
import { withRouter } from "../withRouter";
import Hammer from 'hammerjs';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataPeminjaman: [],
            DataBukuOnline: [],
            ModeView: "History",
            D1: saiki(1),
            D2: saiki()
        };
        this.tabContentRef = createRef();
    }

    async componentDidMount() {
        this.handleMain();
        this.hammer = new Hammer(this.tabContentRef.current);
        this.hammer.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL });
        this.hammer.on('swipeleft panleft', () => this.activateTab('next'));
        this.hammer.on('swiperight panright', () => this.activateTab('prev'));
    }

    handleNavigate = (path) => {
        this.props.navigate(path);
    };

    componentWillUnmount() {
        if (this.hammer) {
            this.hammer.destroy();
        }
    }

    activateTab = (direction) => {
        const tabs = Array.from(document.querySelectorAll('.nav-link')); // Ambil semua tab
        const activeTab = document.querySelector('.nav-link.active'); // Tab aktif
        const activeIndex = tabs.indexOf(activeTab); // Index tab aktif

        let newIndex = activeIndex; // Index baru
        if (direction === 'next' && activeIndex < tabs.length - 1) {
            newIndex = activeIndex + 1; // Geser ke kanan
        } else if (direction === 'prev' && activeIndex > 0) {
            newIndex = activeIndex - 1; // Geser ke kiri
        }

        if (newIndex !== activeIndex) {
            tabs[newIndex].click();
        }
    };

    async handleMain() {
        if (this.state.ModeView == "History") {
            let sql = await api("controler/perpus_api", { act: "data peminjaman murid", D1: this.state.D1, D2: this.state.D2 }, true);
            if (sql.status == "sukses") this.setState({ DataPeminjaman: sql.data });
        } else if (this.state.ModeView == "Online") {
            let sql = await api("controler/perpus_api", { act: "data buku online" }, true);
            if (sql.status == "sukses") this.setState({ DataBukuOnline: sql.data });
        }
        document.getElementById('loadingSpiner').style.display = "none";
    }

    handleChangeMode(Mode) {
        document.getElementById('loadingSpiner').style.display = "block";
        this.setState({ ModeView: Mode });
        setTimeout(() => {
            this.handleMain();
        }, 500);
    }

    render() {
        return (
            <Fragment>
                <div className='container'>
                    <div className="d-flex align-items-center">
                        <i
                            className="fas fa-chevron-left"
                            onClick={() => this.handleNavigate("/academy")}
                            style={{ cursor: "pointer" }}
                        ></i>
                        <h5 className="flex-grow-1 text-center m-0">Perpustakaan</h5>
                    </div>
                    <p></p>
                    <ul className="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={this.state.ModeView == "History" ? "nav-link active" : "nav-link"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => this.handleChangeMode("History")}>Piminjaman</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={this.state.ModeView == "Online" ? "nav-link active" : "nav-link"} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => this.handleChangeMode("Online")}>Buku Online</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent" ref={this.tabContentRef}>
                        <div className={this.state.ModeView == "History" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0" style={{ height: "90vh", minHeight: "90vh" }}>
                            <p></p>
                            <div className='d-flex'>
                                <input type='date' className='form-control' value={this.state.D1} onChange={(e) => this.setState({ D1: e.target.value })} />
                                <input type='date' className='form-control' value={this.state.D2} onChange={(e) => this.setState({ D2: e.target.value })} />
                                <button className='btn btn-default' onClick={() => this.handleMain()}>
                                    <i className='fas fa-search'></i>
                                </button>
                            </div>
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>Tanggal</th>
                                            <th>Judul</th>
                                            <th>Tanggal Kembali</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.DataPeminjaman.length > 0
                                                ?
                                                this.state.DataPeminjaman.map((tr, i) => {
                                                    return (<tr key={i} onClick={() => this.handleBtnAdd(tr.ID)}>
                                                        <td>{tr.Hari}</td>
                                                        <td>{tr.Jam}</td>
                                                        <td>{tr.Pelajaran}</td>
                                                        <td>{tr.Kelas}</td>
                                                    </tr>)
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>Tidak ada data</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={this.state.ModeView == "Online" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0" style={{ height: "90vh", minHeight: "90vh" }}>
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>Judul</th>
                                            <th>Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.DataBukuOnline.length > 0
                                                ?
                                                this.state.DataBukuOnline.map((tr, i) => {
                                                    return (<tr key={i}>
                                                        <td>{tr.Judul}</td>
                                                        <td>
                                                            <a href={tr.Rak} target='_blank' >Buka</a>
                                                        </td>
                                                    </tr>)
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>Tidak ada data</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(Home);