import React, { Fragment, createRef } from 'react';
import { pesan, submitForm, api, saiki, exportData, openModal } from '../Module';
import QRCode from 'qrcode.react';
import Hammer from 'hammerjs';
import { DetectorResult } from '@zxing/library';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataPelajaran: [],
            DataEks: [],
            DataMaPel: [],
            DetailPelajaran: [],
            Detail: {},
            Tanggal: saiki(),
            JamMulai: "07:00",
            JamSelesai: "09:00",
            IDPelajaran: 0,
            Notes: "",
            ID: "",
            Mode: "Akademis",
            LinkQr: "kosong",
            host: window.location.hostname === "localhost" ? "http://localhost/siswaapi" : "https://siswaapi.naylatools.com"
        };
        this.tabContentRef = createRef();
    }

    async componentDidMount() {
        this.handleMain();
        document.getElementById('loadingSpiner').style.display = "none";
        this.hammer = new Hammer(this.tabContentRef.current);
        this.hammer.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL });
        this.hammer.on('swipeleft panleft', () => this.activateTab('next'));
        this.hammer.on('swiperight panright', () => this.activateTab('prev'));
    }

    componentWillUnmount() {
        if (this.hammer) {
            this.hammer.destroy();
        }
    }

    activateTab = (direction) => {
        const tabs = Array.from(document.querySelectorAll('.nav-link'));
        const activeTab = document.querySelector('.nav-link.active');
        const activeIndex = tabs.indexOf(activeTab);

        let newIndex = activeIndex;
        if (direction === 'next' && activeIndex < tabs.length - 1) {
            newIndex = activeIndex + 1;
        } else if (direction === 'prev' && activeIndex > 0) {
            newIndex = activeIndex - 1;
        }

        if (newIndex !== activeIndex) {
            tabs[newIndex].click();
        }
    };

    async handleMain() {
        let sql = await api("controler/guru_api", { act: "data jadwal" }, true);
        if (sql.status == "sukses") this.setState({
            DataPelajaran: sql.pelajaran,
            DataEks: sql.eks,
            Tanggal: saiki(),
            JamMulai: "07:00",
            JamSelesai: "09:00",
            IDPelajaran: 0,
            Notes: "",
            ID: "",
        });
        document.getElementById('loadingSpiner').style.display = "none";
    }

    async handleFormPelajaran(ID = "") {
        document.getElementById('btnCloseDetail').click();
        let sql = await api("controler/guru_api", { act: "data belajar", ID: ID });
        if (sql.status == "sukses") {
            if (sql.data != false) {
                this.setState({
                    DataMaPel: sql.Mapel,
                    ID: ID,
                    Tanggal: sql.data.Tanggal,
                    JamMasuk: sql.data.JamMasuk,
                    JamPulang: sql.data.JamPulang,
                    IDPelajaran: sql.data.IDPelajaran,
                    Notes: sql.data.Notes
                });
            } else {
                this.setState({ DataMaPel: sql.Mapel, ID: ID });
            }
            openModal("modalAdd");
        } else {
            pesan(sql.pesan);
        }
    }

    async handleDetailBelajar(IDKelas, IDPelajaran, Hari, ID) {
        let cek = await api("controler/guru_api", { act: "cek jurnal kelas", IDKelas, IDPelajaran, Hari });
        if (cek.status == "sukses") {
            let sql = await api("controler/guru_api", { act: "detail jadwal pelajaran", ID: cek.data.ID }, true);
            if (sql.status == "sukses") {
                this.setState({ DetailPelajaran: sql.data, ID: cek.ID, LinkQr: sql.Tokens });
                openModal("modalDetail");
            } else {
                pesan(sql.pesan);
            }
        } else {
            this.setState({ Detail: { IDKelas, IDPelajaran, Hari, ID } });
            openModal("modalKonfirmasi");
            document.getElementById('btnTutupModalDetail').click();
        }
    }

    async handleExpoer(ID) {
        let sql = await api("controler/guru_api", { act: "data belajar", ID: ID }, true);
        if (sql.status == "sukses") {
            let Field = [
                { field: "NIS", text: "NIS" },
                { field: "Nama", text: "Nama Siswa" },
                { field: "Jam", text: "JamMulai" },
                { field: "Status", text: "Status" },
            ]
            exportData(this.state.DetailPelajaran, `Data kegiatan ${sql.data.NamaPelajaran}`, Field);
        }
    }

    async handleLoginKelas() {
        let sql = await api("model/guru_crud", { act: "login kelas", IDKelas: this.state.Detail.IDKelas, IDPelajaran: this.state.Detail.IDKelas, ID: this.state.Detail.ID })
        if (sql.status == "sukses") {
            this.handleDetailBelajar(sql.data.IDKelas, sql.data.IDPelajaran);
            document.getElementById('btnTutupModalKonfirmasi').click();
        } else {
            pesan(sql.pesan);
        }
    }

    render() {
        return (
            <Fragment>
                <div className='container'>
                    <h5 style={{ textAlign: "center" }}>Home</h5>
                    <ul className="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" onClick={() => {
                                this.setState({ Mode: "Akademis" });
                                setTimeout(() => {
                                    this.handleMain();
                                }, 500);
                            }} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                Jadwal Mengajar
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={() => {
                                this.setState({ Mode: "Ekstrakurikuler" });
                                setTimeout(() => {
                                    this.handleMain();
                                }, 500);
                            }} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                Jadwal Ekskul
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent" ref={this.tabContentRef}>
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0" style={{ height: "90vh", minHeight: "90vh" }}>
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th>Jam</th>
                                            <th>Pelajaran</th>
                                            <th>Kelas</th>
                                            <th>Selesai</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.DataPelajaran.length > 0 ?
                                            this.state.DataPelajaran.map((tr, i) => {
                                                return (
                                                    <tr key={i} onClick={() => this.handleDetailBelajar(tr.IDKelas, tr.IDPelajaran, tr.Hari, tr.ID)}>
                                                        <td>{tr.JamMulai}</td>
                                                        <td>{tr.Pelajaran}</td>
                                                        <td>{tr.Kelas}</td>
                                                        <td>{tr.JamSelesai}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={4}>Tdak ada jadwal hari ini</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0" style={{ height: "90vh", minHeight: "90vh" }}>
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th>Mulai</th>
                                            <th>Ekstrakurikuler</th>
                                            <th>Kelas</th>
                                            <th>Selesai</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.DataEks.length > 0 ?
                                            this.state.DataEks.map((tr, i) => {
                                                return (
                                                    <tr key={i} onClick={() => this.handleDetailBelajar(tr.ID)}>
                                                        <td>{tr.JamMulai}</td>
                                                        <td>{tr.Pelajaran}</td>
                                                        <td>{tr.Kelas}</td>
                                                        <td>{tr.JamSelesai}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={4}>Tdak ada jadwal hari ini</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="modalAbsen" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Kegiatan</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btnCloseDetail'></button>
                            </div>
                            <div className="modal-body">
                                <div className='d-flex justify-content-center align-item-center'>
                                    <QRCode value={this.state.LinkQr} size={200} />
                                </div>
                                <div className='table-resposive'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>NIS</th>
                                                <th>Nama</th>
                                                <th>Jam</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.DetailPelajaran.length > 0 ?
                                                this.state.DetailPelajaran.map((tr, i) => {
                                                    return (<tr key={i}>
                                                        <td>{tr.NIS}</td>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.JamMulai}</td>
                                                        <td>{tr.Status}</td>
                                                    </tr>)
                                                })
                                                : <tr><td colSpan={4}>Tidak ada siswa</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalDetail'>Tutup</button>
                                <button type="button" className="btn btn-default" onClick={() => this.handleExpoer()}><i className='fas fa-download'></i> Download</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalKonfirmasi" tabIndex="-1" aria-labelledby="modalAbsen" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Konfirmasi</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btnCloseDetail'></button>
                            </div>
                            <div className="modal-body">
                                <h5>Apakah anda yakin masuk ke kelas ini!!</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalKonfirmasi'>Batal</button>
                                <button type="button" className="btn btn-default" onClick={() => this.handleLoginKelas()}><i className='fas fa-sign-in-alt'></i> Masuk</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Home;